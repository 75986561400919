import { type FC } from "react";
interface Active {
  active?: boolean;
}
const OrderIcon: FC<Active> = ({ active }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3333 5.33341C22.5732 5.33341 23.1932 5.33341 23.7019 5.46971C25.0822 5.83958 26.1604 6.91777 26.5303 8.29814C26.6666 8.80681 26.6666 9.42679 26.6666 10.6667V22.9334C26.6666 25.1736 26.6666 26.2937 26.2306 27.1494C25.8471 27.902 25.2352 28.5139 24.4825 28.8974C23.6269 29.3334 22.5068 29.3334 20.2666 29.3334H11.7333C9.49304 29.3334 8.37294 29.3334 7.51729 28.8974C6.76464 28.5139 6.15272 27.902 5.76923 27.1494C5.33325 26.2937 5.33325 25.1736 5.33325 22.9334V10.6667C5.33325 9.42679 5.33325 8.80681 5.46955 8.29814C5.83942 6.91777 6.91761 5.83958 8.29798 5.46971C8.80664 5.33341 9.42662 5.33341 10.6666 5.33341M11.9999 20.0001L14.6666 22.6667L20.6666 16.6667M12.7999 8.00008H19.1999C19.9467 8.00008 20.32 8.00008 20.6052 7.85476C20.8561 7.72693 21.0601 7.52295 21.1879 7.27207C21.3333 6.98685 21.3333 6.61348 21.3333 5.86675V4.80008C21.3333 4.05334 21.3333 3.67998 21.1879 3.39476C21.0601 3.14388 20.8561 2.9399 20.6052 2.81207C20.32 2.66675 19.9467 2.66675 19.1999 2.66675H12.7999C12.0532 2.66675 11.6798 2.66675 11.3946 2.81207C11.1437 2.9399 10.9397 3.14388 10.8119 3.39476C10.6666 3.67998 10.6666 4.05334 10.6666 4.80008V5.86675C10.6666 6.61349 10.6666 6.98685 10.8119 7.27207C10.9397 7.52295 11.1437 7.72693 11.3946 7.85476C11.6798 8.00008 12.0532 8.00008 12.7999 8.00008Z"
        stroke={active ? "#FD633D" : "#626468"}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrderIcon;
