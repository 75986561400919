import { z } from "zod";
// import { OrderSchema } from "../schema/schema";
import { createResponseSchema } from "@/shared/services/createResponseSchema";

// const ChangeHourseWorkResponseSchema = z.object({
//   ticket: OrderSchema,
// });


export const ChangeHourseWorkResponseDto = createResponseSchema(z.string());

