

const ENV_VARIABLES = {
  BACKEND_URL,
  APP_MODE,
  REACT_APP_VERSION,
  PROJECT_ID,
  API_LOGS_ENDPOINT
};


export const CONFIG_APP = {
  API_ENDPOINT: ENV_VARIABLES.BACKEND_URL,
  ACCESS_TOKEN_COOKIE: "ACCESS_TOKEN_RAPLE_WEB",
  REFRESH_TOKEN_COOKIE: "REFRESH_TOKEN_RAPLE_WEB",
  START_PAGE_AFTER_LOGIN: "/",
  MODE: ENV_VARIABLES.APP_MODE,
  PROJECT_ID: ENV_VARIABLES.PROJECT_ID,
  API_LOGS_ENDPOINT: ENV_VARIABLES.API_LOGS_ENDPOINT,
  APP_VERSION: ENV_VARIABLES.REACT_APP_VERSION,
} as const;
