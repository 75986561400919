import { CalendarService } from "../service/service";
import { CalendarAdapter } from "../store/adapter";
import { NotificationService } from "@/shared/services/notification";
import { LoadingController } from "@/shared/services/loading";
import * as dto from "../dto";
import type { DataHoursModel } from "../schema/schema";
import { socketIO } from "@/shared/services/soket/SoketIO";
import { authStore, authStoreType } from "@/entities/auth";

class CalendarController {
  private readonly storeAuth: authStoreType;

  constructor(
    private readonly orderService: CalendarService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: CalendarAdapter,
    private readonly loadingController: LoadingController
  ) {
    this.storeAuth = authStore;
  }

  public setCurrentDayHouses(hours: DataHoursModel[]) {
    const currentDay = new Date().toLocaleDateString();

    this.storeAdapter.setCurrentDayHoursList(hours);

    if (new Date(hours[0].time).toLocaleDateString() === currentDay) {
      this.storeAdapter.setCurrenDaytHours(hours);
    }
  }

  public setIsFreelanceHaveDebt(value: boolean) {
    this.storeAdapter.setIsFreelanceHaveDebt(value)
  }

  public setCurrentHous(hour: DataHoursModel) {
    this.storeAdapter.setCurrentHours(hour);
  }

  public setCurrentDate(date: Date) {
    this.storeAdapter.setCurrentDate(date);

    const profileId = this.storeAuth.getState().profile?.id;

    if (profileId) {
      socketIO.handheGetTicket(date, profileId);
    }
  }

  public setCancelSocketLoading() {
    this.storeAdapter.setCancelSocketLoading();
  }

  public fetchChangeHoursWork(payload: dto.ChangeHourseWorkDto, id: number) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchChangeHoursWork(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.changeHourWorkStatus(id);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        )
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }
}

export const calendarController = new CalendarController(
  new CalendarService(),
  new NotificationService(),
  new CalendarAdapter(),
  new LoadingController()
);
