import { OrderService } from "../service/service";
import { OrderAdapter } from "../store/adapter";
import { NotificationService } from "@/shared/services/notification";
import { LoadingController } from "@/shared/services/loading";
import * as dto from "../dto";
import { OrderModel } from "../schema/schema";

class OrderController {
  constructor(
    private readonly orderService: OrderService,
    private readonly notificationService: NotificationService,
    private readonly storeAdapter: OrderAdapter,
    private readonly loadingController: LoadingController,
  ) {}

  public setCurrentOrder(order: OrderModel) {
    this.storeAdapter.setCurrentOrder(order);
  }

  public openModalStartAcceptance() {
    this.storeAdapter.openModalStartAcceptance();
  }

  public closeModalStartAcceptance() {
    this.storeAdapter.closeModalStartAcceptance();
  }

  public openReportModal() {
    this.storeAdapter.openReportModal();
  }

  public closeReportModal() {
    this.storeAdapter.closeReportModal();
  }

  public openThisTimeCant() {
    this.storeAdapter.openThisTimeCant();
  }

  public closeThisTimeCant() {
    this.storeAdapter.closeThisTimeCant();
  }

  public getAllOrderNotExpired() {
    this.loadingController.activeFetchLoading();
    this.orderService
      .fetchAllOrderNotExpired()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.storeAdapter.setOrdersListNotExpired(response.data.tickets);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.loadingController.resetFethLoading());
  }

  public getOrder(id: string): Promise<string | null> {
    return new Promise((resolve) => {
      this.loadingController.activeFetchLoading();
      this.orderService
        .fetchOrder(id)
        .then((response) => {
          if (response.status === "success" && response.data) {
            this.storeAdapter.setCurrentOrder(response.data.ticket);
            resolve(response.data.ticket.timeToAccept);
          } else {
            resolve(response.data?.ticket?.timeToAccept ? response.data.ticket.timeToAccept : null);
            throw response.message;
          }
        })
        .catch((error) =>
          console.error({ error }),
          // this.notificationService.activeNotification({
          //   status: "error",
          //   message: error,
          // })
        )
        .finally(() => this.loadingController.resetFethLoading());
    });
  }

  public resetOrder() {
    this.storeAdapter.resetOrder();
  }

  public acceptOrdreInWork(masterId: string, orderId: number) {
    return new Promise((resolve) => {
    let status = 'error';
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchAcceptOrdreInWork(masterId, orderId)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Заявка успешно взята в работу",
          });
          this.storeAdapter.changeOrderStatus("В работе");
        } else {
          throw response.message;
        }
        status = response.status;
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => (this.storeAdapter.resetLoadingSumbmit(), resolve(status)));
    })
  }

  public completedOrder(
    payload: FormData,
  ) {
    return new Promise((resolve) => {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchCompletedOrder(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {

          this.notificationService.activeNotification({
            status: "success",
            message: "Заявка успешно завершена",
          });

          resolve(response.status);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
    })
  }

  public cancelOrder(payload: FormData) {
    return new Promise((resolve) => {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchCancelOrder(payload)
      .then((response) => {
        if (response.status === "success" && response.data) {
          console.log(response);
          this.notificationService.activeNotification({
            status: "success",
            message: "Заявка успешно отменена",
          });
          resolve(response.status);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => (this.storeAdapter.resetLoadingSumbmit()));
    })
  }

  public acceptOrderNotExpired(id: string, date: string) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchAcceptOrderNotExpired(id, date)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Вы приняли заказа к выполнению",
          });

          this.storeAdapter.filterOrderNotExpired(id);
          this.closeModalStartAcceptance();
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public cancelOrderNotExpired(id: string, date: string) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchCancelOrderNotExpired(id, date)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Вы отказались от заказа",
          });

          this.storeAdapter.filterOrderNotExpired(id);
          this.closeModalStartAcceptance();
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public sendReportMessage(payload: { comment: string, date: string }, id: string) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchReportMessage(payload, id)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Сообщение доставлено",
          });

          this.closeReportModal();
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public dontWorkThisDay(id: string, date: string) {
    return new Promise((resolve) => {
      this.storeAdapter.setLoadingSubmit();
      this.orderService
      .fetchDontWorkThisDay(id, date)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Вы отказались от заказа на этот день",
          });
          
          this.closeModalStartAcceptance();
          this.storeAdapter.filterOrderNotExpired(id);
        } else {
          throw response.message;
        }
        resolve(response.status);
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
          ),
          )
          .finally(() => this.storeAdapter.resetLoadingSumbmit());
        })
        }
        
        public cantOrderNotExpired(id: string, timeBegin: string) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchCantOrderNotExpired(id, timeBegin)
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.notificationService.activeNotification({
            status: "success",
            message: "Вы отказались от заказа на это время",
          });

          this.closeModalStartAcceptance();
          this.storeAdapter.filterOrderNotExpired(id);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public cancelOrderContinueWork(date: string) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchCancelOrderContinueWork(date)
      .then((response) => {
        if (response.status === "success" && response.data) {
         

          this.notificationService.activeNotification({
            status: "success",
            message: "Вы отказались от заявки но выполните назначенные",
          });

          this.closeThisTimeCant();
          // this.storeAdapter.filterOrderNotExpired((id));
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }

  public cancelOrderCancelWorkDay(date: string) {
    this.storeAdapter.setLoadingSubmit();
    this.orderService
      .fetchCancelOrderCancelWorkDay(date)
      .then((response) => {
        if (response.status === "success" && response.data) {
          console.log(response);

          this.notificationService.activeNotification({
            status: "success",
            message: "Вы отказались от заявок на текущий день",
          });

          this.closeThisTimeCant();
          // this.storeAdapter.filterOrderNotExpired((id));
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.storeAdapter.resetLoadingSumbmit());
  }
}

export const orderController = new OrderController(
  new OrderService(),
  new NotificationService(),
  new OrderAdapter(),
  new LoadingController(),
);
