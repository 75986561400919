import { z } from "zod";

export const TicketCategorySchema = z.object({
  id: z.number(),
  category_name: z.string(),
});

export const OrderSchema = z.object({
  id: z.number(),
  category: TicketCategorySchema,
  type: z.string(),
  description: z.string(),
  price: z.number(),
  phone: z.string(),
  client_name: z.string(),
  address: z.string(),
  work_hours: z.number(),
  read_cost: z.string().optional(),
  transfer: z.number(),
  prettier_id: z.string(),
  region: z.string().nullable(),
  timeBegin: z.string().optional().nullable(),
  date_create: z.string(),
  date_update: z.string(),
  date_delete: z.string().nullable(),
  status: z.string(),
  amoId: z.number().nullable(),
  manager_id: z.number(),
  amoURL: z.string(),
  timeEnd: z.string().optional().nullable(),
  guarantee: z.boolean().optional(),
  guaranteeData: z.unknown().nullable(),
  timeToAccept: z.string().nullable(),
  isTiketTaken: z.boolean().optional(),
  isTakenToWork: z.boolean().optional(),
});

const OrderInitStateSchema = z.object({
  currentOrder: OrderSchema.nullable(),
  loadingSubmit: z.boolean(),
  ordersNotExpired: z.array(OrderSchema),
  startOrderAcceptance: z.boolean(), 
  reportModal: z.boolean(), 
  modalthisTimeCant: z.boolean(), 
});

export type TicketCategoryModel = z.infer<typeof TicketCategorySchema>;
export type OrderModel = z.infer<typeof OrderSchema>;
export type OrderInitState = z.infer<typeof OrderInitStateSchema>;
