import { z } from "zod";

export const UserSchema = z.object({
  id: z.number(),
  username: z.string().nullable(),
  email: z.string().nullable(),
  role: z.string().nullable(),
  avatar: z.string().nullable(),
});

export const BalanceSchema = z.object({
  curBalance: z.string(),
  balance: z.string(),
  debt: z.string(),
  compensation: z.string(),
  fuelCompensation: z.string(),
});


const AuthInitStateSchema = z.object({
  profile: UserSchema.nullable(),
  authorization: z.boolean(),
  loading: z.boolean(),
  balance: BalanceSchema.nullable(),
});

export type BalanceModel = z.infer<typeof BalanceSchema>;
export type UserModel = z.infer<typeof UserSchema>;
export type AuthInitState = z.infer<typeof AuthInitStateSchema>;
