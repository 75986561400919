import { createStore } from "@/shared/services/create-store";
import { OrderInitState } from "../schema/schema";


const initState: OrderInitState = {
  loadingSubmit: false,
  ordersNotExpired: [],
  currentOrder: null,
  startOrderAcceptance: false,
  reportModal: false,
  modalthisTimeCant: false,
};

export const orderStore = createStore({ initState });

export type OrderStoreType = typeof orderStore;