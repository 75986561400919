import { createStore } from "@/shared/services/create-store";
import { CalendarInitState } from "../schema/schema";


const initState: CalendarInitState = {
  hoursList: [],
  currentHour: null,
  currentSelectDay: new Date(),
  loadingSubmit: false,
  isOpenModalChangeHours: false,
  socketLoading: true,
  currentDayHourses: [],
  isFreelanceHaveDebt: false
};

export const calendarStore = createStore({ initState });

export type CalendarStoreType = typeof calendarStore;
