import { BalanceModel, UserModel } from "../schema/schema";
import { authStore, authStoreType } from "./store";


export class AuthAdapter {
  public store: authStoreType;
  constructor() {
    this.store = authStore;
  }

  public setProfile(profile: UserModel) {
    return this.store.setState({ profile, authorization: true });
  }

  public setBalance(balance: BalanceModel) {
    return this.store.setState({ balance });
  }

  public logout() {
    return this.store.setState({ profile: null, authorization: false });
  }
 
  public setLoading() {
    return this.store.setState({ loading: true });
  }

  public resetLoading() {
    return this.store.setState({ loading: false });
  }

}


