import { z } from "zod";

export const OrdersParamsSchema = z.object({
  limit: z.string().optional(),
  page: z.string().optional(),
});

export type CompletedOrderPayload = {
  ticket_id: number;
  master_id: number;
  income: number;
  comment: string;
  img: string;
};

export type OrdersParamsDto = z.infer<typeof OrdersParamsSchema>;
