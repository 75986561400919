import { type FC } from "react";
interface Active {
  active?: boolean;
}
const ProfileIcon: FC<Active> = ({ active }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={active ? "1" : "0.3"} clipPath="url(#clip0_2381_569)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 17.3333C19.1947 17.3333 22.1 18.2587 24.2373 19.5613C25.304 20.2147 26.216 20.9813 26.8747 21.8147C27.5227 22.636 28 23.6173 28 24.6667C28 25.7933 27.452 26.6813 26.6627 27.3147C25.916 27.9147 24.9307 28.312 23.884 28.5893C21.78 29.1453 18.972 29.3333 16 29.3333C13.028 29.3333 10.22 29.1467 8.116 28.5893C7.06933 28.312 6.084 27.9147 5.33733 27.3147C4.54667 26.68 4 25.7933 4 24.6667C4 23.6173 4.47733 22.636 5.12533 21.8147C5.784 20.9813 6.69467 20.2147 7.76267 19.5613C9.9 18.2587 12.8067 17.3333 16 17.3333ZM16 20C13.3027 20 10.876 20.7867 9.152 21.8387C8.28933 22.364 7.63867 22.9347 7.21867 23.468C6.78667 24.0147 6.66667 24.428 6.66667 24.6667C6.66667 24.8293 6.716 25.0013 7.00667 25.2347C7.33867 25.5013 7.916 25.7773 8.79867 26.0107C10.556 26.4773 13.0813 26.6667 16 26.6667C18.92 26.6667 21.444 26.476 23.2 26.0107C24.084 25.7773 24.6613 25.5013 24.9933 25.2347C25.284 25.0013 25.3333 24.8293 25.3333 24.6667C25.3333 24.4267 25.2133 24.0147 24.7827 23.468C24.3613 22.9347 23.7107 22.364 22.848 21.8387C21.124 20.7853 18.6973 20 16 20ZM16 2.66666C17.7681 2.66666 19.4638 3.36904 20.714 4.61928C21.9643 5.86952 22.6667 7.56521 22.6667 9.33332C22.6667 11.1014 21.9643 12.7971 20.714 14.0474C19.4638 15.2976 17.7681 16 16 16C14.2319 16 12.5362 15.2976 11.286 14.0474C10.0357 12.7971 9.33333 11.1014 9.33333 9.33332C9.33333 7.56521 10.0357 5.86952 11.286 4.61928C12.5362 3.36904 14.2319 2.66666 16 2.66666ZM16 5.33332C15.4747 5.33332 14.9546 5.43679 14.4693 5.63781C13.984 5.83882 13.543 6.13346 13.1716 6.5049C12.8001 6.87633 12.5055 7.31729 12.3045 7.80259C12.1035 8.28789 12 8.80804 12 9.33332C12 9.85861 12.1035 10.3788 12.3045 10.8641C12.5055 11.3494 12.8001 11.7903 13.1716 12.1618C13.543 12.5332 13.984 12.8278 14.4693 13.0288C14.9546 13.2299 15.4747 13.3333 16 13.3333C17.0609 13.3333 18.0783 12.9119 18.8284 12.1618C19.5786 11.4116 20 10.3942 20 9.33332C20 8.27246 19.5786 7.25504 18.8284 6.5049C18.0783 5.75475 17.0609 5.33332 16 5.33332Z"
          fill={active ? "#FD633D" : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2381_569">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProfileIcon;
