import { type FC } from "react";

const OrangeTriangle: FC = () => {
  return (
    <svg
      width="258.000000"
      height="278.000000"
      viewBox="0 0 258 278"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <filter
          id="filter_2374_3669_dd"
          x="-0.470459"
          y="-0.930664"
          width="257.493408"
          height="278.914673"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0" dy="9" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.99216 0 0 0 0 0.38824 0 0 0 0 0.23922 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect_dropShadow_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect_dropShadow_1"
            result="shape"
          />
        </filter>
      </defs>
      <g filter="url(#filter_2374_3669_dd)">
        <path
          id="Polygon 2"
          d="M217.11 190.81L69.66 250.95C40.39 262.89 9.5 237.76 15.21 206.68L46.26 37.87C51.98 6.78 89.79 -5.71 112.9 15.86L229.3 124.53C250.64 144.45 244.15 179.78 217.11 190.81Z"
          fill="#FD633D"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default OrangeTriangle;
