import { type FC } from "react";

const SearchIcon: FC = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 17 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M-0.34 10.83L-0.34 9.16L8 9.16L8 10.83L-0.34 10.83ZM-0.34 6.66L-0.34 5L3.83 5L3.83 6.66L-0.34 6.66ZM-0.34 2.5L-0.34 0.83L3.83 0.83L3.83 2.5L-0.34 2.5ZM15.16 10.83L11.95 7.62C11.62 7.86 11.26 8.03 10.86 8.15C10.46 8.27 10.06 8.33 9.66 8.33C8.51 8.33 7.53 7.92 6.71 7.11C5.9 6.3 5.49 5.31 5.5 4.16C5.5 3.01 5.9 2.03 6.71 1.21C7.53 0.4 8.51 -0.01 9.66 0C10.81 0 11.8 0.4 12.61 1.21C13.42 2.03 13.83 3.01 13.83 4.16C13.83 4.56 13.77 4.96 13.65 5.36C13.53 5.76 13.36 6.12 13.12 6.45L16.33 9.66L15.16 10.83ZM9.66 6.66C10.36 6.66 10.95 6.42 11.43 5.93C11.92 5.45 12.16 4.86 12.16 4.16C12.16 3.47 11.92 2.88 11.43 2.39C10.95 1.9 10.36 1.66 9.66 1.66C8.97 1.66 8.38 1.9 7.89 2.39C7.4 2.88 7.16 3.47 7.16 4.16C7.16 4.86 7.4 5.45 7.89 5.93C8.38 6.42 8.97 6.66 9.66 6.66Z"
        fill="#1F2227"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
};
export default SearchIcon;
