import { CookieService } from "@/shared/services/cookie/CookieService";
import { CONFIG_APP } from "../../config";

type RefreshTokenResponse = {
  status: "success" | "error";
  data: { tokens: { accessToken: string; refreshToken: string } } | null;
  message: string | null;
};

export const getToken = () => CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE);
export const getRefreshToken = () =>
  CookieService.get(CONFIG_APP.REFRESH_TOKEN_COOKIE);

export const fetchRefreshToken = () => {
  const url = new URL(`${CONFIG_APP.API_ENDPOINT}/api/refresh`);
  const refreshToken = getRefreshToken();

  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  })
    .then((response) => response.json())
    .then((response: RefreshTokenResponse) => {
      if (response.status === "success" && response.data) {
        const { accessToken, refreshToken } = response.data.tokens;
        CookieService.set(accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE);
        CookieService.set(refreshToken, CONFIG_APP.REFRESH_TOKEN_COOKIE);
        return accessToken;
      } else {
        CookieService.delete(CONFIG_APP.ACCESS_TOKEN_COOKIE);
        CookieService.delete(CONFIG_APP.REFRESH_TOKEN_COOKIE);
        return null;
      }
    });
};


type SendLogParamsType = {
  type: string;
  message: string;
  user_id?: string;
  version?: string;
  mode: "dev" | "prod";
  additional_context?: any;
  project_id: string;
};

export const fetchSendLogs = (response: Response, json: any, payloadBody: object | undefined ) => {
  const payload: SendLogParamsType = {
    message: json.message,
    mode: CONFIG_APP.MODE === "development" ? "dev" : "prod",
    project_id: CONFIG_APP.PROJECT_ID,
    version: CONFIG_APP.APP_VERSION,
    type: json.status ? json.status : "error",
    additional_context: {
      body: response.body,
      bodyUsed: response.bodyUsed,
      headers: response.headers,
      ok: response.ok,
      redirected: response.redirected,
      status: response.status,
      statusText: response.statusText,
      type: response.type,
      url: response.url,
      data: json.data,
      timeLocal: new Date().toLocaleString(),
      date: new Date(),
      payload: payloadBody,
    },
    user_id: "",
  };

  fetch(CONFIG_APP.API_LOGS_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
