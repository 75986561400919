import { type FC } from "react";

const ArrowIcon: FC = () => {
  return (
    <svg
      width="10"
      height="15"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 15L10 13.25L4.25 7.5L10 1.75L8.25 0L0.750001 7.5L8.25 15Z"
        fill="#1F2227"
      />
    </svg>
  );
};
export default ArrowIcon;
