import { type FC } from "react";
import { toastIcon } from "@/shared/lib/toast/get-icon";
import { CloseIcon } from "../icons";

export type ToastType = "success" | "error" | "warning";

interface ToastProps {
  title: string;
  body: string;
  type: ToastType;
  close: () => void;
}

import styles from "./toast.module.scss";

const Toast: FC<ToastProps> = ({ title, body, type, close }) => {
  return (
    <div className={`${styles.root} ${styles[type]}`}>
      <div className={styles.content}>
        <button>{toastIcon[type]}</button>
        <div className={styles.text}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.body}>{body}</p>
        </div>
      </div>
      <button onClick={close}>
        <CloseIcon toast={true} />
      </button>
    </div>
  );
};

export { Toast };
