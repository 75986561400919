import { type FC } from "react";

const OrangeTriangleSmall: FC = () => {
  return (
    <svg
      width="190.000000"
      height="188.000000"
      viewBox="0 0 190 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <filter
          id="filter_2374_3670_dd"
          x="-0.660645"
          y="-0.246094"
          width="189.700684"
          height="187.364380"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0" dy="9" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.99216 0 0 0 0 0.38824 0 0 0 0 0.23922 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect_dropShadow_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect_dropShadow_1"
            result="shape"
          />
        </filter>
      </defs>
      <g filter="url(#filter_2374_3670_dd)">
        <path
          id="Polygon 3"
          d="M96.71 156.11L31.77 111.73C5.52 93.79 9.68 53.87 39.08 41.73L118.74 8.84C148.13 -3.29 179.25 22.07 173.3 53.31L158.57 130.57C153.17 158.88 120.51 172.37 96.71 156.11Z"
          fill="#FD633D"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default OrangeTriangleSmall;
