export type OrderEndpointsType = {
  getAllOrder: string;
  getOne: (id: string) => string;
  acceptOrdreInWork: (id: string) => string;
  completedOrder: string;
  cancelOrder: string;
  acceptOrderNotExpired: (id: string) => string;
  cancelOrderNotExpired: (id: string) => string;
  sendReportMessage: (id: string) => string;
  dontWorkThisDay: (id: string) => string;
  cantOrderNotExpired: (id: string) => string;
  cancelOrderContinueWork: string;
  cancelOrderCancelWorkDay: string;
};

export const orderEndpoints: OrderEndpointsType = {
  getAllOrder: "freelance/queue", // список заказов у которых не истекло время на принятие
  getOne: (id: string) => `ticket/${id}`, // получаем заказ по id
  acceptOrdreInWork: (id: string) => `worker/master/inProgress/${id}`, // принимаем заказ в работу
  completedOrder: "completed_ticket/freelance", // оправить выполненый заказ
  cancelOrder: "canceled_ticket", // отменить выполняемый заказ
  acceptOrderNotExpired: (id: string) => `freelance/accept/${id}`, //принимаем заказ в очереди не принятых
  cancelOrderNotExpired: (id: string) => `freelance/cancel/${id}`, //отказатся от заказа в очереди не принятых
  sendReportMessage: (id: string) => `freelance/trouble/${id}`, //сообщить о проблеме в очереди не принятых
  dontWorkThisDay: (id: string) => `freelance/non_work/${id}`, //Мастер не работает в данный день
  cantOrderNotExpired: (id: string) => `freelance/cant/${id}`, //Отказатся от заявки если  нет заявок на текущий день
  cancelOrderContinueWork: `freelance/non_work/cancel_time`, // Я выполню назначенные, но этот не возьму
  cancelOrderCancelWorkDay:  `freelance/non_work/cancel_ticket`, // Отменить все заказы на текущий день
};
