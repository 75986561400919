import { AuthService } from "../service/service";
import { AuthAdapter } from "../state/adapter";
import * as dto from "../dto";
import {
  CookieService,
  CookieServiceType,
} from "@/shared/services/cookie/CookieService";
import { CONFIG_APP } from "@/shared/config";
import { NotificationService } from "@/shared/services/notification";

export class AuthController {
  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly authAdapter: AuthAdapter,
    private readonly cookieService: CookieServiceType,
  ) {}

  public login(payload: dto.AuthorizationDto) {
    return new Promise((resolve) => {
      this.authAdapter.setLoading();
      this.authService
        .authorization(payload)
        .then((response) => {
          if (response.status === "success" && response.data) {
            if (response.data.tokens) {
              this.setToken(response.data.tokens);
            }

            this.authAdapter.setProfile(response.data.user);

            return resolve("success");
          } else {
            throw response.message;
          }
        })
        .catch((error) => {
          console.error(error);
          return resolve(error);
        })
        .finally(() => this.authAdapter.resetLoading());
    });
  }

  public logout() {
    return this.authAdapter.logout(), this.resetToken();
  }

  public getBalance() {
    this.authAdapter.setLoading();
    this.authService
      .fetchBalance()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.authAdapter.setBalance(response.data);
        } else {
          throw response.message;
        }
      })
      .catch(
        (error) => (
          console.error(error),
          this.notificationService.activeNotification({
            status: "error",
            message: error,
          })
        ),
      )
      .finally(() => this.authAdapter.resetLoading());
  }

  public refreshToken() {
    return this.authService
      .fethRefreshToken()
      .then((response) => {
        if (response.status === "success" && response.data) {
          this.setToken(response.data.tokens);
          return response.data.tokens.accessToken;
        } else {
          throw response.message;
        }
      })
      .catch((error) => {
        console.error(error);
        this.notificationService.activeNotification({
          status: "error",
          message: error,
        });
        throw error;
      });
  }

  private setToken({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) {
    this.cookieService.set(accessToken, CONFIG_APP.ACCESS_TOKEN_COOKIE);
    this.cookieService.set(refreshToken, CONFIG_APP.REFRESH_TOKEN_COOKIE);
  }

  private resetToken() {
    this.cookieService.delete(CONFIG_APP.ACCESS_TOKEN_COOKIE);
    this.cookieService.delete(CONFIG_APP.REFRESH_TOKEN_COOKIE);
  }
}

export const authController = new AuthController(
  new AuthService(),
  new NotificationService(),
  new AuthAdapter(),
  CookieService,
);
