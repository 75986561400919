import { useEffect, useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../main-navbar/Navbar";
import { NotificationList } from "@/widgets/notification/notification-list/NotificationList";
import { socketIO } from "@/shared/services/soket/SoketIO";
import { CookieService } from "@/shared/services/cookie/CookieService";
import { CONFIG_APP } from "@/shared/config";
import { calendarController } from "@/entities/calendar";
import { authController } from "@/entities/auth";

import styles from "./MainLayout.module.scss";

const MainLayout = () => {
  useEffect(() => {
    authController.getBalance();
    return () => {
      socketIO.disconect();
    };
  }, []);

  useLayoutEffect(() => {
    socketIO.connectIO(CookieService.get(CONFIG_APP.ACCESS_TOKEN_COOKIE)!);
    calendarController.setCurrentDate(new Date());
  }, []);

  return (
    <div className={styles.root}>
      <main className={styles.content}>
        <Outlet />
      </main>
      <NotificationList />
      {/*<MainFooterNotification />*/}
      <Navbar />
    </div>
  );
};

export { MainLayout };
