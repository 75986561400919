import { z } from "zod";
import { OrderSchema } from "../schema/schema";
import { createResponseSchema } from "@/shared/services/createResponseSchema";

const OrderResponseSchema = z.object({
  ticket: OrderSchema,
});

const OrdersListNotExpiredResponseSchema = z.object({
  tickets: z.array(OrderSchema),
});

const OrderDontWorkThisDayResponseSchema = z.object({
  message: z.string(),
  isHaveTickets: z.boolean(),
});

export const OrdersListNotExpiredResponseDto = createResponseSchema(
  OrdersListNotExpiredResponseSchema,
);

export const CurrentOrderResponseDto =
  createResponseSchema(OrderResponseSchema);

export const AcceptNotOrderResponseDto = createResponseSchema(z.string());
export const OrderDontWorkThisDayResponseDto = createResponseSchema(OrderDontWorkThisDayResponseSchema);
