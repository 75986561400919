import { FetchService } from "@/shared/api/fetch-api";
import { CalendarRepository } from "../repository/repository";
import * as dto from "../dto";


export class CalendarService {
  private readonly repository: CalendarRepository;

  constructor() {
    this.repository = new CalendarRepository(new FetchService());
  }

  public fetchChangeHoursWork(payload: dto.ChangeHourseWorkDto) {
    return this.repository.fetchChangeHoursWork(payload);
  }

}
