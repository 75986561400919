import { z } from "zod";
import { OrderSchema } from "@/entities/order";


export const DataHoursSchema = z.object({
  id: z.number(),
  time: z.string(),
  isWork: z.boolean(),
  ticket: OrderSchema.nullable(),
  isContainsTakenTicket: z.boolean(),
});


const CalendarInitStateSchema = z.object({
  hoursList: z.array(DataHoursSchema),
  currentHour: DataHoursSchema.nullable(),
  loadingSubmit: z.boolean(),
  socketLoading: z.boolean(),
  isOpenModalChangeHours: z.boolean(),
  currentSelectDay: z.string().transform(dateString => new Date(dateString)).nullable(),
  currentDayHourses: z.array(DataHoursSchema),
  isFreelanceHaveDebt: z.boolean()
});



export type DataHoursModel = z.infer<typeof DataHoursSchema>;
export type CalendarInitState = z.infer<typeof CalendarInitStateSchema>;
