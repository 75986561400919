import { AuthRepository } from "../repositiry/repository";
import * as dto from "../dto";
import { FetchService } from "@/shared/api/fetch-api";


export class AuthService {
  private readonly repository: AuthRepository;

  constructor() {
    this.repository = new AuthRepository(new FetchService());
  }

  public authorization(payload: dto.AuthorizationDto) {
    return this.repository.fethAuthorization(payload);
  }

  public fethRefreshToken() {
    return this.repository.fethRefreshToken();
  }

  public fetchBalance() {
    return this.repository.fetchBalance();
  }

}
