import { CalendarIcon, OrderIcon, ProfileIcon } from "@/shared/ui/icons";


type NavbarDataType = {
  id: number;
  icon: JSX.Element;
  href: string;
}

export const navBarData: NavbarDataType[] = 
 [
    {
      id: 1,
      icon: <CalendarIcon />,
      href: `/`,
    },
    {
      id: 2,
      icon: <OrderIcon />,
      href: `/order`,
    },
    {
      id: 3,
      icon: <ProfileIcon />,
      href: `/profile`,
    },
  ];

