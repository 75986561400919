export const NAV = {
  HOME: "/",
  LEAD: "lead",
  PROFILE: "profile",
  ORDERS: "order",
  ORDER: "orders/details/:id",
  INSTUCTION: "instruction",
  UNAUTHORIZED: "unauthorized",
  NOTFOUND: "*",
};
