import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import { routerApp } from "./app/router";

import './app/styles/globals.scss';

const root = document.getElementById("root");

if (!root) {
  throw new Error("Root element not found");
}

const container = createRoot(root);


container.render(
  <Suspense fallback={ <div>Loading...</div>}>
  <RouterProvider router={routerApp} />
  </Suspense>,
);

//  if ('serviceWorker' in navigator) {
//      window.addEventListener('load', () => {
//        navigator.serviceWorker.register('/service-worker.js')
//       .then(registration => {
//          console.log('SW registered: ', registration);
//        }).catch(registrationError => {
//          console.log('SW registration failed: ', registrationError);
//        });
//      });
//    }
