import { type FC } from "react";
const BlackTriangle: FC = () => {
  return (
    <svg
      width="276.000000"
      height="251.000000"
      viewBox="0 0 276 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <filter
          id="filter_2374_3017_dd"
          x="-0.902832"
          y="-0.115479"
          width="276.852295"
          height="250.205566"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0" dy="9" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.12157 0 0 0 0 0.13333 0 0 0 0 0.15294 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect_dropShadow_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect_dropShadow_1"
            result="shape"
          />
        </filter>
      </defs>
      <g filter="url(#filter_2374_3017_dd)">
        <path
          id="Polygon 2"
          d="M87.89 208.26L16.73 48.08C7.1 26.41 25.22 2.67 48.66 6.24L235.43 34.68C258.86 38.24 269.1 66.3 253.46 84.12L137.85 215.87C123.41 232.32 96.78 228.27 87.89 208.26Z"
          fill="#1F2227"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default BlackTriangle;
