import { type FC } from "react";

const ArrowIcon: FC = () => {
  return (
    <svg
      width="9.000000"
      height="15.000000"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M7.25 15L9 13.25L3.25 7.5L9 1.75L7.25 0L-0.25 7.5L7.25 15Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default ArrowIcon;
