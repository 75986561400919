export type AuthEndpointsType = {
  login: string;
  refresh: string;
  allBalance: string;
};

export const authEndpoints: AuthEndpointsType = {
  login: "login/freelance",
  refresh: "refresh",
  allBalance: 'worker/master/balance',
};
