import { type FC } from "react";

const CircleIcon: FC = () => {
  return (
    <svg
      width="296.000000"
      height="286.000000"
      viewBox="0 0 296 286"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs>
        <filter
          id="filter_2374_3647_dd"
          x="0.000000"
          y="0.000000"
          width="296.000000"
          height="286.000000"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="10" in="" result="BackgroundImageFix" />
          <feOffset dx="0" dy="4" />
          <feGaussianBlur stdDeviation="6.66667" />
          <feComposite in2="hardAlpha" operator="out" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.99216 0 0 0 0 0.38824 0 0 0 0 0.23922 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect_dropShadow_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect_dropShadow_1"
            result="shape"
          />
        </filter>
      </defs>
      <g filter="url(#filter_2374_3647_dd)">
        <ellipse
          id="Ellipse 22"
          cx="148.000000"
          cy="139.000000"
          rx="118.000000"
          ry="113.000000"
          fill="#FD633D"
          fillOpacity="1.000000"
        />
      </g>
    </svg>
  );
};

export default CircleIcon;
