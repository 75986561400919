import { z } from "zod";
import { createResponseSchema } from "@/shared/services/createResponseSchema";
import { BalanceSchema, UserSchema } from "../schema/schema";

const TokenSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});

const AuthorizationResponseSchema = z.object({
  user: UserSchema,
  tokens: TokenSchema,
});

export const RefreshTokenResponseSchema = z.object({
  tokens: TokenSchema,
});

export const AuthorizationResponseDto = createResponseSchema(
  AuthorizationResponseSchema,
);
export const RefreshTokenResponseDto = createResponseSchema(
  RefreshTokenResponseSchema,
);
export const BalanceResponseDto = createResponseSchema(BalanceSchema);
