import { DataHoursModel } from "../schema/schema";
import { calendarStore, type CalendarStoreType } from "./store";


export class CalendarAdapter {
  private store: CalendarStoreType;

  constructor() {
    this.store = calendarStore;
  }

  public setCurrentHours(currentHour: DataHoursModel) {
    return this.store.setState({ currentHour });
  }


  public setIsFreelanceHaveDebt(value: boolean) {
    return this.store.setState({ isFreelanceHaveDebt: value })
  }

  public setCurrenDaytHours(currentDayHourses: DataHoursModel[]) {
    return this.store.setState({ currentDayHourses });
  }

  public setCurrentDayHoursList(hoursList: DataHoursModel[]) {
    return this.store.setState({ hoursList });
  }

  public setCurrentDate(currentSelectDay: Date) {
    return this.store.setState({ currentSelectDay });
  }

  public setCancelSocketLoading() {
    const socketLoading = this.store.getState().socketLoading;
    if (!socketLoading) return;
    return this.store.setState({ socketLoading: false });
  }

  public changeHourWorkStatus(id: number) {
    const currentHoursList = this.store.getState().hoursList;

    if (!currentHoursList.length) return;
    return this.store.setState({ hoursList: currentHoursList.map(item => item.id === id ? ({ ...item, isWork: !item.isWork }) : item) });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }

}


