import { io, type Socket } from "socket.io-client";
import { CONFIG_APP } from "@/shared/config";
import { notificationService } from "../notification";
import { calendarController } from "@/entities/calendar";
import { TimeServices } from "../formattedTime";

class SocketIO {
  private socket: Socket;
  public connectedSocket: boolean = false;
  private token: string | null = null;

  constructor({ endpoint }: { endpoint: string }) {
    this.socket = io(endpoint, {
      autoConnect: false,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      auth: { token: this.token },
    });
  }

  public connectIO(token: string) {
    if (!this.connectedSocket) {
      this.token = token;
      this.socket.auth = { token };
      this.socket.connect();
      this.listeners();
    }
  }

  private onConnect() {
    notificationService.activeNotification({
      status: "success",
      message: "Соединение установленно",
    });
    this.connectedSocket = true;
  }

  private onDisconnect() {
    console.log("Disconect ws");
    notificationService.activeNotification({
      status: "error",
      message: "Соединение разорвано",
    });
    this.connectedSocket = false;
  }

  private onError(error: Error) {
    console.error("Ошибка ws:", error);
    notificationService.activeNotification({
      status: "error",
      message: error.message,
    });
  }

  private onConnectError(error: Error) {
    console.error("Ошибка соединения ws:", error);
    notificationService.activeNotification({
      status: "error",
      message: error.message,
    });
  }

  private onGetTicketToMaster(data: any) {
    calendarController.setCurrentDayHouses(data.data[0].hours);
    calendarController.setCancelSocketLoading();
    calendarController.setIsFreelanceHaveDebt(data.isFreelanceHaveDebt)
  }

  public handheGetTicket(date: Date | string, profileId: number) {
    if (!profileId) return;

    const payload = {
      masterId: profileId,
      date:
        date instanceof Date ? TimeServices.getInputDateFromParams(date) : date,
      gridCount: 2,
    };

    this.socket.emit("ticket-to-master-get", payload);
  }

  private listeners() {
    this.socket.on("connect", this.onConnect);
    this.socket.on("disconnect", this.onDisconnect);
    this.socket.on("error", this.onError);
    this.socket.on("connect_error", this.onConnectError);
    this.socket.on("ticket-to-master-get", this.onGetTicketToMaster);
    this.socket.emit("ticket-to-master-get", this.handheGetTicket);
  }

  public disconect() {
    this.socket.off("connect", this.onConnect);
    this.socket.off("disconnect", this.onDisconnect);
    this.socket.off("error", this.onError);
    this.socket.off("connect_error", this.onConnectError);
    this.socket.off("ticket-to-master-get", this.onGetTicketToMaster);
    this.socket.off("ticket-to-master-get", this.handheGetTicket);
    this.socket.disconnect();
  }
}

export const socketIO = new SocketIO({
  endpoint: CONFIG_APP.API_ENDPOINT,
});
