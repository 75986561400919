import { orderEndpoints, type OrderEndpointsType } from "./endpoints";
import * as dto from "../dto";
import type { FetchService } from "@/shared/api/fetch-api";

export class OrderRepository {
  private readonly endpoints: OrderEndpointsType = orderEndpoints;

  constructor(private readonly fethService: FetchService) {}

  public fetchAllOrderNotExpired() {
    const url = this.endpoints.getAllOrder;

    return this.fethService
      .get({ url })
      .then((data) => dto.OrdersListNotExpiredResponseDto.parseAsync(data));
  }

  public fetchOrder(id: string) {
    const url = this.endpoints.getOne(id);

    return this.fethService
      .get({ url })
      .then((data) => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchAcceptOrdreInWork(masterId: string, orderId: number) {
    const url = this.endpoints.acceptOrdreInWork(masterId);

    return this.fethService.put({ url, payload: { inProgress: orderId } }); //TODO 1 || id
    // .then((data) => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchCompletedOrder(payload: FormData) {
    const url = this.endpoints.completedOrder;

    return this.fethService.post({ url, payload });
    // .then((data) => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchCancelOrder(payload: FormData) {
    const url = this.endpoints.cancelOrder;

    return this.fethService.post({ url, payload });
    // .then((data) => dto.CurrentOrderResponseDto.parseAsync(data));
  }

  public fetchAcceptOrderNotExpired(id: string, timeBegin: string) {
    const url = this.endpoints.acceptOrderNotExpired(id);

    return this.fethService
      .post({ url, payload: { timeBegin } })
      .then((data) => dto.AcceptNotOrderResponseDto.parseAsync(data));
  }

  public fetchCancelOrderNotExpired(id: string, timeBegin: string) {
    const url = this.endpoints.cancelOrderNotExpired(id);

    return this.fethService
      .post({ url, payload: { timeBegin } })
      .then((data) => dto.AcceptNotOrderResponseDto.parseAsync(data));
  }

  public fetchReportMessage(payload: { comment: string, date: string  }, id: string) {
    const url = this.endpoints.sendReportMessage(id);

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.AcceptNotOrderResponseDto.parseAsync(data));
  }

  public fetchDontWorkThisDay(id: string, timeBegin: string) {
    const url = this.endpoints.dontWorkThisDay(id);

    return this.fethService
      .post({ url, payload: { date: timeBegin } })
      .then((data) => dto.OrderDontWorkThisDayResponseDto.parseAsync(data));
  }

  public fetchCantOrderNotExpired(id: string, timeBegin: string) {
    const url = this.endpoints.cantOrderNotExpired(id);

    return this.fethService
      .post({ url, payload: { timeBegin } })
      .then((data) => dto.AcceptNotOrderResponseDto.parseAsync(data));
  }

  public fetchCancelOrderContinueWork(date: string) {
    const url = this.endpoints.cancelOrderContinueWork;

    return this.fethService.post({ url, payload: { date } });
    // .then((data) => dto.AcceptNotOrderResponseDto.parseAsync(data));
  }

  public fetchCancelOrderCancelWorkDay(date: string) {
    const url = this.endpoints.cancelOrderCancelWorkDay;

    return this.fethService.post({ url, payload: { date } });
    // .then((data) => dto.AcceptNotOrderResponseDto.parseAsync(data));
  }
}
