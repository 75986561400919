import { lazy } from "react";


export const MainLazyPage = lazy(() => import("./main/MainPage"));
export const OrdersLazyPage = lazy(() => import("./orders"));
export const ProfileLazyPage = lazy(() => import("./profile"));
export const UnathorizedLazyPage = lazy(() => import("./unauthorized/UnauthorizedPage"));
export const InstructionLazyPage = lazy(() => import("./instruction"));
export const OrderDetailsLazyPage = lazy(() => import("./OrderDetailPage"));
export const LeadLazyPage = lazy(() => import("./lead/Lead"));
export const NotFoundLazyPage = lazy(() => import("./not-found/NotFoundPage"));
