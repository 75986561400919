import type { OrderModel } from "../schema/schema";
import { orderStore, type OrderStoreType } from "./store";

export class OrderAdapter {
  private store: OrderStoreType;

  constructor() {
    this.store = orderStore;
  }

  public setCurrentOrder(currentOrder: OrderModel) {
    return this.store.setState({ currentOrder });
  }

  public openModalStartAcceptance() {
    return this.store.setState({ startOrderAcceptance: true });
  }

  public closeModalStartAcceptance() {
    return this.store.setState({ startOrderAcceptance: false });
  }

  public openReportModal() {
    return this.store.setState({ reportModal: true });
  }

  public closeReportModal() {
    return this.store.setState({ reportModal: false });
  }

  public openThisTimeCant() {
    return this.store.setState({ modalthisTimeCant: true });
  }

  public closeThisTimeCant() {
    return this.store.setState({ modalthisTimeCant: false });
  }

  public resetOrder() {
    return this.store.setState({ currentOrder: null });
  }

  public filterOrderNotExpired(id: string) {
    const prevOrderNotExpired = this.store.getState().ordersNotExpired;
    if (prevOrderNotExpired.length === 0) return;
    return this.store.setState({
      ordersNotExpired: prevOrderNotExpired.filter(
        (item) => item.id !== Number(id)
      ),
    });
  }

  public setOrdersListNotExpired(ordersNotExpired: OrderModel[]) {
    return this.store.setState({ ordersNotExpired });
  }

  public changeOrderStatus(value: string) {
    const prevOrder = this.store.getState().currentOrder;
    if (!prevOrder) return;
    return this.store.setState({
      currentOrder: { ...prevOrder, status: value },
    });
  }

  public setLoadingSubmit() {
    return this.store.setState({ loadingSubmit: true });
  }

  public resetLoadingSumbmit() {
    return this.store.setState({ loadingSubmit: false });
  }
}
