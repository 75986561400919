import {  cloneElement } from "react";
import {  useLocation, Link } from "react-router-dom";
import { navBarData } from "./navbar-data";

import styles from "./Navbar.module.scss";


const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <ul className={styles.root}>
      {navBarData.map((item) => (
        <Link
        to={item.href}
          key={item.id}
        >
          {cloneElement(item.icon, { active: item.href === pathname })}
        </Link>
      ))}
    </ul>
  );
};

export { Navbar };
