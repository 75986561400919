import { createStore } from "@/shared/services/create-store";
import { AuthInitState } from "../schema/schema";


const initState: AuthInitState = {
  profile: null,
  authorization: false,
  loading: false,
  balance: null,
};


export const authStore = createStore({
  initState,
  persistName: 'auth-store',
});

export type authStoreType = typeof authStore;
