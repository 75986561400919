import { z } from "zod";

export const ChangeHourseWorkSchema = z.object({
  master_id: z.number(),
  duration: z.number(),
  time: z.string(),
  date: z.string(),
});



export type ChangeHourseWorkDto = z.infer<typeof ChangeHourseWorkSchema>;
