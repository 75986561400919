import { type FC } from "react";

const ErrorIcon: FC = () => {
  return (
    <svg
      width="25.880859"
      height="25.880951"
      viewBox="0 0 25.8809 25.881"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M12.94 25.13C6.2 25.13 0.75 19.67 0.75 12.94C0.75 6.2 6.2 0.75 12.94 0.75C19.67 0.75 25.13 6.2 25.13 12.94C25.13 19.67 19.67 25.13 12.94 25.13Z"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="1.500000"
        strokeLinejoin="round"
      />
      <path
        id="Vector"
        d="M8.36 8.36L17.51 17.51M17.51 8.36L8.36 17.51"
        stroke="#FFFFFF"
        strokeOpacity="1.000000"
        strokeWidth="1.500000"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIcon;
