import { FetchService } from "@/shared/api/fetch-api";
import { OrderRepository } from "../repository/repository";
import * as dto from "../dto";


export class OrderService {
  private readonly repository: OrderRepository;

  constructor() {
    this.repository = new OrderRepository(new FetchService());
  }

  public fetchAllOrderNotExpired() {
    return this.repository.fetchAllOrderNotExpired();
  }

  public fetchOrder(id: string) {
    return this.repository.fetchOrder(id);
  }
  
  public fetchAcceptOrdreInWork(masterId: string, orderId: number) {
    return this.repository.fetchAcceptOrdreInWork(masterId, orderId);
  }

  public fetchCompletedOrder(payload: FormData) {
    return this.repository.fetchCompletedOrder(payload);
  }

  public fetchCancelOrder(payload: FormData) {
    return this.repository.fetchCancelOrder(payload);
  }

  public fetchAcceptOrderNotExpired(id: string, date: string) {
    return this.repository.fetchAcceptOrderNotExpired(id, date);
  }

  public fetchCancelOrderNotExpired(id: string, date: string) {
    return this.repository.fetchCancelOrderNotExpired(id, date);
  }
  
  public fetchReportMessage(payload: { comment: string, date: string }, id: string) {
    return this.repository.fetchReportMessage(payload, id);
  }
  
  public fetchDontWorkThisDay(id: string, date: string) {
    return this.repository.fetchDontWorkThisDay(id, date);
  }

  public fetchCantOrderNotExpired(id: string, timeBegin: string) {
    return this.repository.fetchCantOrderNotExpired(id, timeBegin);
  }

  public fetchCancelOrderContinueWork(date: string) {
    return this.repository.fetchCancelOrderContinueWork(date);
  }
  
  public fetchCancelOrderCancelWorkDay(date: string) {
    return this.repository.fetchCancelOrderCancelWorkDay(date);
  }
 

}
