import { calendarEndpoints, type CalendarEndpointsType } from "./endpoints";
import * as dto from "../dto";
import type { FetchService } from "@/shared/api/fetch-api";


export class CalendarRepository {

  private readonly endpoints: CalendarEndpointsType = calendarEndpoints;

  constructor(private readonly fethService: FetchService) {}

  public fetchChangeHoursWork(payload: dto.ChangeHourseWorkDto) {
    const url = this.endpoints.changeHouseState;

    return this.fethService
      .post({ url, payload })
      .then((data) => dto.ChangeHourseWorkResponseDto.parseAsync(data));
  }


}




