import { type FC } from "react";

const SuccessIcon: FC = () => {
  return (
    <svg
      width="25.599976"
      height="25.600006"
      viewBox="0 0 25.6 25.6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <desc>Created with Pixso.</desc>
      <defs />
      <path
        id="Vector"
        d="M12.8 0C16.19 0 19.45 1.34 21.85 3.74C24.25 6.14 25.6 9.4 25.6 12.8C25.6 16.19 24.25 19.45 21.85 21.85C19.45 24.25 16.19 25.6 12.8 25.6C9.4 25.6 6.14 24.25 3.74 21.85C1.34 19.45 0 16.19 0 12.8C0 9.4 1.34 6.14 3.74 3.74C6.14 1.34 9.4 0 12.8 0ZM12.8 1.6C11.32 1.6 9.87 1.88 8.51 2.45C7.15 3.01 5.92 3.84 4.88 4.88C3.84 5.92 3.01 7.15 2.45 8.51C1.88 9.87 1.59 11.32 1.59 12.8C1.59 14.27 1.88 15.72 2.45 17.08C3.01 18.44 3.84 19.67 4.88 20.71C5.92 21.75 7.15 22.58 8.51 23.14C9.87 23.71 11.32 24 12.8 24C15.77 24 18.61 22.82 20.71 20.71C22.81 18.61 24 15.77 24 12.8C24 9.82 22.81 6.98 20.71 4.88C18.61 2.78 15.77 1.6 12.8 1.6ZM18.17 9.03C18.3 9.16 18.38 9.34 18.4 9.52C18.42 9.71 18.37 9.9 18.26 10.05L18.17 10.16L11.76 16.57C11.63 16.7 11.45 16.78 11.27 16.8C11.08 16.82 10.89 16.77 10.74 16.66L10.63 16.57L7.43 13.37C7.29 13.23 7.2 13.04 7.19 12.84C7.18 12.64 7.25 12.44 7.38 12.29C7.51 12.13 7.69 12.03 7.89 12.01C8.08 11.98 8.29 12.03 8.45 12.14L8.56 12.24L11.2 14.87L17.04 9.03C17.19 8.88 17.39 8.79 17.6 8.79C17.81 8.79 18.02 8.88 18.17 9.03Z"
        fill="#FFFFFF"
        fillOpacity="1.000000"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default SuccessIcon;
